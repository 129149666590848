import React from "react";
import Jury from "../Jury";

type Props = {};

const Juries = (props: Props) => {
  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold text-white text-center">Jüriler</h2>
      <div className="flex justify-center flex-wrap gap-4 mt-2">
        <Jury />
        <Jury />
        <Jury />
        <Jury />
      </div>
    </div>
  );
};

export default Juries;
