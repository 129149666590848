import Header from "../components/Mainpage/Header";
import EventTimer from "../components/Mainpage/EventTimer";
import Contact from "../components/Mainpage/Contact";
import WhatIsGamejam from "../components/Mainpage/WhatIsGamejam";
import WhoIsBBT from "../components/Mainpage/WhoIsBBT";
import Juries from "../components/Mainpage/Juries";
import PreviousGamejam from "../components/Mainpage/PreviousGamejam";
import SideEvents from "../components/Mainpage/SideEvents";
import Awards from "../components/Mainpage/Awards";
import FaqAccordion from "../components/Mainpage/FaqAccordion";
import SponsorCarousel from "../components/SponsorCarousel";

type Props = {};

const Mainpage = (props: Props) => {
  return (
    <div
      className="flex flex-col gap-8"
      style={{
        minHeight: "calc(100vh - 48px)",
      }}
    >
      <Header />
      <Awards />
      <EventTimer />
      <Contact />
      <SponsorCarousel
        sponsors={[
          "logo.svg",
          "logo-bbt.png",
          "./sponsors/madrooster-renkli.png",
          "./sponsors/SwishSwoosh Logo_Dark BG.png",
          "./sponsors/keramika.png",
          "./sponsors/TaleWorlds_logo_final_white.png",
          "./sponsors/OSE Logo-transparent.png",
          "./sponsors/logo_circle_contrast.png",
          "./sponsors/dpuyorumluyorlogo.png",
        ]}
      />
      <WhatIsGamejam />
      <Juries />
      <WhoIsBBT />
      <PreviousGamejam />
      <SideEvents />
      <FaqAccordion />
    </div>
  );
};

export default Mainpage;
