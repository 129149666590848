import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Mainpage from "./pages/Mainpage";
import CustomParticles from "./components/CustomParticles";
import Sponsors from "./pages/Sponsors";
import Register from "./pages/Register";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CustomParticles />
    <HashRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Mainpage />} />
          <Route path="/sponsorlar" element={<Sponsors />} />
          <Route path="/kayit" element={<Register />} />
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);
