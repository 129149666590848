import React from "react";

type EventProps = {
  img: string;
  name: string;
  dateTime: string;
};
const Event = (props: EventProps) => {
  return (
    <div className="flex flex-col items-center hover:scale-110 transition-all">
      <img src={props.img} alt="" />
      <p className="text-xl font-bold text-white mt-3">{props.name}</p>
      <span className="text-slate-400">{props.dateTime}</span>
    </div>
  );
};

type Props = {};

const SideEvents = (props: Props) => {
  return (
    <section
      className="grid mt-8"
      style={{ gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))" }}
    >
      <Event
        img="./card-games.png"
        name="Masa Oyunu Yarışması"
        dateTime="25.10.2024 - 19:00"
      />
      <Event
        img="./team.png"
        name="E-Spor Yarışması"
        dateTime="26.10.2024 - 19:00"
      />
      <Event
        img="./magic-wand.png"
        name="Cos Play Yarışması"
        dateTime="27.10.2024 - 19:00"
      />
    </section>
  );
};

export default SideEvents;
